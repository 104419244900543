import { MutableRefObject, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import EntityTable, { EntityTableProps } from "../../table/EntityTable";
import TableHeader from "../../table/TableHeader";
import { EntityAction, TopAction } from "@/components/actions/actions";

export type LoadedList<T> = {
    list?: T[]
    error?: string
    totalPages?: number
    page?: number
}

interface ListSectionProps<T> extends EntityTableProps<T> {
    load: (page: number) => Promise<LoadedList<T>>
    onRowClick?: (row: T) => void
    rowActions?: (entity: T) => EntityAction<T>[];
    addIcon?: ReactNode;
    addLabel?: string | null;
    onAdd?: () => void
    topActions?: TopAction[]
    callbacks?: MutableRefObject<ListSectionCallbacks | undefined>
}

export type ListSectionCallbacks = {
    refresh: (reset?: boolean) => void
};

export default function ListSection<T>(props: ListSectionProps<T>) {
    const curPage = useRef(1);
    const [totalPages, setTotalPages] = useState(1);
    const [list, setList] = useState([] as T[]);
    const [_, setError] = useState(''); //TODO show error
    const [loading, setLoading] = useState(true);
    const fetch = async (page: number) => {
        const resp = await props.load(page);
        if (page !== curPage.current) {
            return;
        }
        setLoading(false);
        if (resp.error) {
            setError(resp.error);
        } else {
            setList(resp.list || []);
            setTotalPages((resp.totalPages || 0))
            curPage.current = resp.page || 0
        }
    }
    const refresh = useCallback((reset?: boolean) => {
        if (reset) {
            curPage.current = 1;
        }
        fetch(curPage.current)
    }, [])
    useEffect(() => {
        if (props.callbacks) {
            props.callbacks.current = {
                refresh: refresh
            }
        }
    }, [refresh])
    useEffect(() => {
        fetch(curPage.current);
    }, [curPage])
    return !loading && <>
        <TableHeader empty={!list.length} addLabel={props.addLabel} onAdd={props.onAdd} addIcon={props.addIcon} topActions={props.topActions}></TableHeader>
        <EntityTable {...props} 
            list={!loading ? list : undefined} 
            totalPages={totalPages} 
            page={curPage.current} 
            onPageChange={p => curPage.current = p} 
            onRowClick={props.onRowClick}
            style={{ marginTop: 20 }}></EntityTable>
    </>
}