import { Button, Group, TextInput } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { CgSearch } from "react-icons/cg";
import { FaPlus } from "react-icons/fa";
import { TopAction } from "../actions/actions";
import { ReactNode } from "react";

type TableHeaderProps = {
    empty: boolean;
    addIcon?: ReactNode;
    addLabel?: string | null;
    onAdd?: () => void;
    topActions?: TopAction[];
}
export default function TableHeader({ empty, addLabel, onAdd, addIcon, topActions }: TableHeaderProps) {
    const { t } = useTranslation()
    return <Group justify="space-between">
            { !empty ? <TextInput
                styles={{ input: {
                    maxWidth: '350px',
                    borderRadius: 0,
                    border: 'none',
                    borderBottom: '1px solid #f0f0f0',
                }}}
                leftSection={<CgSearch color="#333"></CgSearch>}
                placeholder={t('common.search') + '...'}
            >
            </TextInput> : <div></div> }
            <Group gap={6}>
                { addLabel && <Button size="xs" color="sfy-green" leftSection={addIcon || <FaPlus></FaPlus>}
                    onClick={() => onAdd ? onAdd() : undefined}
                >{addLabel}</Button>}
                { topActions?.map(a => <Button size="xs" key={a.label} color="sfy-dark-blue" leftSection={a.icon}
                    onClick={() => a.execute()}
                >{a.label}</Button>)}
            </Group>
        </Group>
}