import { DashboardsApi, LongMetric, PvDashboard, VulnerableAsset } from "@/libs/client";
import { useProject } from "@/libs/project/ProjectProvider";
import { Alert, Badge, Grid, GridCol, Group, Select, Stack, Table } from "@mantine/core";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import Chart from 'react-apexcharts'
import { useNavigate } from "react-router-dom";
import classes from './VulnerabilityPrioritizationBoard.module.css';
import DashboardCard from "../DashboardCard";
import { useTranslation } from "react-i18next";
import { FaExclamationTriangle } from "react-icons/fa";
import { useContainer } from "@/components/containers/ContainerProvider";

const PRIORITY_COLORS = ["#e65a0a", "#ffd500", "#2bcd69", "#2a86bc", "#50bfd8"]
const PRIORITIES = ["p1", "p2", "p3", "p4", "p5"]
const getPriorityColor = (p: string) => PRIORITY_COLORS[PRIORITIES.indexOf(p)]!

function VulnerabilityCountCard({ priority, size, count }: { count: number, priority: string, size: 'sm' | 'lg' }) {
    const color = getPriorityColor(priority)
    return <DashboardCard title={<Stack gap={2}><Badge color={color} style={{marginTop: -4}}>{priority}</Badge> Vulnerabilities</Stack>}>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '-0.75rem', height: '100%'}}>
            <div style={{fontWeight: 300, fontSize: size === 'sm' ? '2.5rem' : '6rem'}}>
                {count}
            </div>
        </div>
    </DashboardCard>
}

type PERIOD_CHOICE = 'LAST_3_MONTHS' | 'LAST_30_DAYS';
type PRIORITY_CHOICE = 'all' | 'p1' | 'p2' | 'p3' | 'p4' | 'p5';

function VulnerabiltyLineGraphCard({ installedIntegrationId }: { installedIntegrationId?: string}) {
    //TODO handle closed
    const { curProject } = useProject()
    const [priority, setPriority] = useState<PRIORITY_CHOICE>('all')
    const priorities: PRIORITY_CHOICE[] = ['all', 'p1', 'p2', 'p3', 'p4', 'p5']
    const [period, setPeriod] = useState<PERIOD_CHOICE>('LAST_30_DAYS')
    const periods: PERIOD_CHOICE[] = ['LAST_3_MONTHS', 'LAST_30_DAYS']
    const [metrics, setMetrics] = useState<LongMetric[]>([])
    const fetchMetrics = async () => {
        const resp = await new DashboardsApi().pvMetrics(curProject.id!, period, priority !== 'all' ? priority : undefined, installedIntegrationId || undefined)
        setMetrics(resp.data)
    }
    useEffect(() => {
        fetchMetrics()
    }, [installedIntegrationId, priority, period])
    const nextPeriod = () => setPeriod(periods[(periods.indexOf(period) + 1) % periods.length])
    const nextPriority = () => setPriority(priorities[(priorities.indexOf(priority) + 1) % priorities.length])
    const periodCtx = {
        'LAST_3_MONTHS': {
            count: 3,
            period: 'MONTHS'
        }, 
        'LAST_30_DAYS': {
            count: 30,
            period: 'DAYS'
        }
    }
    const xAxis = useMemo(() => {
        if (period == 'LAST_30_DAYS') {
            return metrics
                .map(p => moment(p.date))
                .map(p => ({ key: p.date(), label: p.format('DD') }))
        }
        return metrics
            .map(p => moment(p.date))
            .map(p => ({ key: p.month(), label: p.format('MMM') }))
    }, [metrics])
    return <DashboardCard title="Vunerability Over Time">
        <div style={{position: 'absolute', top: 30, right: 20, fontWeight: 700, fontSize: '0.9rem'}}>
            <Group>
                <div style={{cursor:'pointer', userSelect: 'none'}} onClick={() => nextPriority()}>{priority.toUpperCase()}</div>
                <div style={{cursor:'pointer', userSelect: 'none'}} onClick={() => nextPeriod()}>LAST <span style={{color: "#12b886"}}>{periodCtx[period].count}</span> {periodCtx[period].period}</div>
            </Group>
        </div>
        <Chart
                series={[
                    {
                      name: 'Vulnerabilities',
                      data: metrics.map(p => p.value),
                      color: '#1f1f1f',
                    }]}
                type="line"
                options={{
                    chart: {
                      type: 'line',
                      toolbar: {
                        show: false,
                      },
                      zoom: {
                        enabled: false,
                      },
                    },
                    stroke: {
                        width: 2,
                        curve: 'smooth'
                    },
                    xaxis: {
                        type: 'category',
                        categories: xAxis.map(p => p.label),
                    },
                }}    
                height={220}
                width={'100%'}
            ></Chart>
    </DashboardCard>
}

function VulerabilityAssetsListCard({ vulnerableAssets } : { vulnerableAssets: VulnerableAsset[] }) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { projectUrl } = useProject()
    const onRowClick = (id: string) => {
        navigate(projectUrl(`/insights/issues?entities=${id}&state=OPEN`))
    }
    return <DashboardCard title="Vulnerable Assets">
        <div style={{minHeight: 150}}>
            <Table striped>
                <Table.Thead>
                    <Table.Th>
                        Asset
                    </Table.Th>
                    <Table.Th>
                        Priority Level
                    </Table.Th>
                    <Table.Th>
                        {t('common.since')}
                    </Table.Th>
                </Table.Thead>
                <Table.Tbody>
                    { vulnerableAssets.map(a => <Table.Tr className={classes.assetRow} key={a.asset?.id} onClick={() => onRowClick(a.asset?.id!!)}>
                        <Table.Td>
                            {a.asset?.display}
                        </Table.Td>
                        <Table.Td>
                            <Group gap={2}>{a.priorities?.map(p => <Badge key={p} color={getPriorityColor(p)}>{p}</Badge>)}</Group>
                        </Table.Td>
                        <Table.Td>
                            {moment(a.date).fromNow()}
                        </Table.Td>
                    </Table.Tr>) }
                </Table.Tbody>
            </Table>
        </div>
    </DashboardCard>
}

function VulnerabilityPriorityChartCard({ countPerPriority } : { countPerPriority: { [key: string]: number } }) {
    const priorities = ["p1", "p2", "p3", "p4", "p5"]
    const data = priorities.map(p => countPerPriority[p] || 0)
    const hasData = data.findIndex(d => d > 0) >= 0
    return <DashboardCard title="Priority Distribution">
            { hasData ? <Chart
                series={data}
                type="donut"
                options={{
                    chart: {
                      type: 'donut',
                    },
                    labels: priorities.map(p => p.toUpperCase()),
                    dataLabels: {
                        enabled: false,
                    },                  
                    colors: PRIORITY_COLORS,
                    plotOptions: {
                        pie: {
                          donut: {           
                            size: '85%',
                          },
                        }
                      }
                }}    
                height={250}
                width={'100%'}
            ></Chart> : <div style={{fontSize: '1.5rem', color: '#c0c0c0', textAlign: 'center', marginTop: '5%'}}>N/A</div> }
    </DashboardCard>
}

function NonPrioritizedVulnerabilitiesModal({ pvDashboard } : { pvDashboard: PvDashboard }) {
    return <Table>
        <Table.Thead>
            <Table.Th>
                Vulnerability
            </Table.Th>
            <Table.Th>
                Assets
            </Table.Th>
        </Table.Thead>
        <Table.Tbody>
            {pvDashboard.vulnerabilitiesNotPrioritized.map(v => <Table.Tr>
                <Table.Td>
                    {v.vulnerabilityDisplay}
                </Table.Td>
                <Table.Td>
                    {v.assets.map(a => a.display).join(", ")}
                </Table.Td>
            </Table.Tr>)}
        </Table.Tbody>
    </Table>
}

export default function VulnerabilityPrioritizationBoard() {
    const { t } = useTranslation()
    const { curProject } = useProject()
    const { openModal } = useContainer()
    const [pvDashboard, setPvDashboard] = useState<PvDashboard>()
    const [installedIntegrationId, setInstalledIntegrationId] = useState<string>()
    const [loadingDashboard, setLoadingDashboard] = useState(false);
    const selectedInstalledIntegration = useMemo(() => {
        return pvDashboard?.installedIntegrations.find(i => i.id == installedIntegrationId)?.name || '';
    }, [installedIntegrationId])
    const fetchPvDashboard = async () => {
        if (loadingDashboard) {
            return;
        }
        setLoadingDashboard(true);
        const installedIntegrationIdParam = installedIntegrationId ? installedIntegrationId : undefined;
        const resp = await new DashboardsApi().pvDashboard(curProject.id!, installedIntegrationIdParam)
        setLoadingDashboard(false);
        setPvDashboard(resp.data)
    }
 
    const p1Count = useMemo(() => pvDashboard?.countPerPriority["p1"] || 0, [pvDashboard])
    const p2Count = useMemo(() => pvDashboard?.countPerPriority["p2"] || 0, [pvDashboard])
    const p3Count = useMemo(() => pvDashboard?.countPerPriority["p3"] || 0, [pvDashboard])
    const p4Count = useMemo(() => pvDashboard?.countPerPriority["p4"] || 0, [pvDashboard])
    const p5Count = useMemo(() => pvDashboard?.countPerPriority["p5"] || 0, [pvDashboard])
    useEffect(() => {
        fetchPvDashboard()
    }, [])
    useEffect(() => {
        fetchPvDashboard()
    }, [installedIntegrationId])
    return pvDashboard && <div>
        <div style={{maxWidth: 1400, margin: 'auto'}}>
        <Stack>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Group>
                <label style={{color: '#505050'}}>Installed Integration</label>
                <Select style={{minWidth: 250}} defaultValue={""} allowDeselect={false} disabled={loadingDashboard} value={installedIntegrationId}
                    onChange={e => setInstalledIntegrationId(e || '')} data={[
                    { value: "", label: t("common.all")},
                    ...(pvDashboard.installedIntegrations || []).map(i => ({
                        value: i.id!,
                        label: i.name!,
                    }))
                ]}></Select>
            </Group>
        </div>
        { pvDashboard.vulnerabilitiesNotPrioritized.length > 0 ? <Alert color="yellow">
            <Group>
                <FaExclamationTriangle color="orange"></FaExclamationTriangle>
                <div>There is <a style={{color: 'black'}} href="" onClick={(e) => { 
                    e.preventDefault();
                    e.stopPropagation(); 
                    openModal('non_prioritized_vuls', <NonPrioritizedVulnerabilitiesModal pvDashboard={pvDashboard}></NonPrioritizedVulnerabilitiesModal>, {
                    title: `Non-Prioritized Vulnerabilities${selectedInstalledIntegration ? ` for ${selectedInstalledIntegration}`: ''} (${pvDashboard.vulnerabilitiesNotPrioritized.length})`
                })}}>{pvDashboard.vulnerabilitiesNotPrioritized.length + ''}</a> vulnerabilities not prioritized{selectedInstalledIntegration ? ` for ${selectedInstalledIntegration}` : ''}</div>
            </Group>
        </Alert> : <></> }
        <Grid>
            <GridCol span={6}>
                <Grid>
                    <GridCol span={5}>
                        <VulnerabilityCountCard count={p1Count} priority="p1" size="lg"></VulnerabilityCountCard>
                    </GridCol>
                    <GridCol span={7}>
                        <Stack>
                        <Grid>
                            <GridCol span={6}>
                                <VulnerabilityCountCard count={p2Count} priority="p2" size="sm"></VulnerabilityCountCard>
                            </GridCol>
                            <GridCol span={6}>
                                <VulnerabilityCountCard count={p3Count} priority="p3" size="sm"></VulnerabilityCountCard>
                            </GridCol>
                        </Grid>
                        <Grid>
                            <GridCol span={6}>
                                <VulnerabilityCountCard count={p4Count} priority="p4" size="sm"></VulnerabilityCountCard>
                            </GridCol>
                            <GridCol span={6}>
                                <VulnerabilityCountCard count={p5Count} priority="p5" size="sm"></VulnerabilityCountCard>
                            </GridCol>
                        </Grid>
                        </Stack>
                    </GridCol>
                </Grid>
            </GridCol>
            <GridCol span={6}>
                <VulnerabiltyLineGraphCard installedIntegrationId={installedIntegrationId}></VulnerabiltyLineGraphCard>
            </GridCol>
        </Grid>
        <Grid>
            <GridCol span={4}>
                <VulnerabilityPriorityChartCard countPerPriority={pvDashboard?.countPerPriority || {}}></VulnerabilityPriorityChartCard>
            </GridCol>
            <GridCol span={8}>
                <VulerabilityAssetsListCard vulnerableAssets={pvDashboard?.vulnerableAssets || []}></VulerabilityAssetsListCard>
            </GridCol>
        </Grid>
        </Stack>
        </div>
    </div>
}